import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import "./appContainer.css";
import theme from "../../app/theme";
import Routing from "../routing";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, MenuItem, Typography } from "@mui/material";
import Cookies from 'universal-cookie';
import { SnackbarProvider } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import FathomClient from "api/fathomapi";
import { useEffect, useState } from "react";
import MiniDrawer from "./drawer";
import Intercom from '@intercom/messenger-js-sdk';
import { update } from "@intercom/messenger-js-sdk";

const cookies = new Cookies();

const drawerWidth: number = 268;


interface Metadata {
  id: "",
  created_at: 0,
  name: "",
  email: "",
  clientRoles: {
    [cId:string]:
      {
        role: "",
        name: ""
      }
  }
}

export default function AppContainer() {


  const { getAccessTokenSilently, isAuthenticated, isLoading  } = useAuth0();
  const { user, loginWithRedirect } = useAuth0();
  const [userClientDto, setUser] = useState({} as Metadata);
  const [clientId, setClientId] = useState("");
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);
  const [clientCookie, setClientCookie] = useState(cookies.get("x-fathom-clientId"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const env = process.env.REACT_APP_ENV;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitch = () => {
    setAnchorEl(null)
    navigate("/select/")
  }
  const location = useLocation();


  const setCookie = () => {
    if(user){
      localStorage.setItem("x-fathom-currentRole", "Admin")
      const roles = user["fathom/client_roles"];
      if(roles != undefined){      

        var envRoles = Object.keys(roles).filter(function(role){ 
          return roles[role].Environment == env;
        });

        if(envRoles.length == 1 && (cookies.get('x-fathom-clientId') == undefined || localStorage.getItem("x-fathom-currentRole") == undefined)){
          cookies.set('x-fathom-clientId', envRoles[0], {
            path: "/"
          } );
          localStorage.setItem("x-fathom-currentRole", roles[envRoles[0]].Role == 1 ? "Admin" : "Viewer")
          window.location.assign("/programs")
        }else{
          if(cookies.get('x-fathom-clientId') == undefined && location.pathname.indexOf("select") == -1 && location.pathname.indexOf("invite") == -1 ){
            navigate("/select/");
          }
        }

        if(cookies.get('x-fathom-clientId') !== undefined && Object.keys(roles).indexOf(cookies.get('x-fathom-clientId')) < 0){
          loginWithRedirect();
        }
      }
    }else {
      
    }
  }

  useEffect(() => {
    if(!isLoading && !isAuthenticated) {
      navigate("/login/");
    }

    setCookie();
    if(env == "Prod"){
      Intercom({
        app_id: 'bf6nqy35',
      });
    }

    const fetchData = async () => {
        const api = new FathomClient(await getAccessTokenSilently())
        const { data } = await api.get(`auth/profile`);
        setUser(data);
        if(env == "Prod"){
          update({ email:userClientDto.email, name:userClientDto.name })
        }
        setLoading(false);
    }

    setClientId(cookies.get('x-fathom-clientId'))    
    setUserLoading(false)

    if(clientCookie === undefined || clientCookie === ""){
      setShowSidebar(false)
    }
  
    fetchData();
    
  }, [isAuthenticated, isLoading, clientCookie])

  const getClientName = () => {
    if(user){
      const roles = user["fathom/client_roles"];
      if(roles != undefined && Object.keys(roles).length == 1){
        return( <Typography variant="h6" sx={{
          marginTop: "20px",
      }}>{!loading && userClientDto?.clientRoles[clientId]?.name}</Typography>)
      }else{
        return (
          <div>
              <Typography sx={{
          marginTop: "20px",
      }} onClick={handleClick} variant="h6">{userClientDto?.clientRoles[clientId]?.name}</Typography>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleSwitch() }>Switch client</MenuItem>
            </Menu>
          </div>
        );
      }
    }
  }



  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider />
      {!userLoading && 
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {showSidebar &&
          <MiniDrawer loading={loading} getClientName={getClientName} user={user}></MiniDrawer>
        }
          <Routing />
            

      </Box>
}
    </ThemeProvider>
  );
}
